import React from 'react'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import faPaperPlane from '@fortawesome/fontawesome-free-solid/faPaperPlane'
import '../css/contact.scss'

const Contact = () => (
	<section className="contact fade-in delay">
		<div className="wrapper">
			<FontAwesomeIcon icon={faPaperPlane} size="6x" />
			<h3>Seen enough?</h3>
			<p className="sub-heading">Get in touch for more details!</p>
			<a href="mailto:me@sjoloughlin.com">Email me</a>
		</div>
	</section>
)

export default Contact
