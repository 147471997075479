import React from 'react'
import { Canvas } from '../components'
import '../css/header.scss'

const Header = () => (
	<section className="header fade-in">
		<header>
			<h1><span className="intro1 fade-in-up">The online portfolio of </span><span className="intro2 fade-in-up">Stephen O'Loughlin</span>
			</h1>
			<h2><span className="intro3 fade-in-up">A Website Designer &amp; Developer from Northampton, UK</span></h2>
		</header>
		<Canvas />
	</section>
)

export default Header
