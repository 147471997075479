import React from 'react'
import '../css/footer.scss'

const Footer = () => (
  <footer className="fade-in delay">
    <p>&copy; {new Date().getFullYear()} Stephen O'Loughlin – Website Designer &amp; Developer</p>
  </footer>
)

export default Footer
