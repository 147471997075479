import React, { Component } from 'react'

export default class Canvas extends Component {

  constructor() {
    super();
    this.state = {
      squares: null,
      mouseX: null,
      mouseY: null,
      request: 0
    };

    this.generateSquares = this.generateSquares.bind(this);
    this.animate = this.animate.bind(this);
    this.resetMouse = this.resetMouse.bind(this);
  }

  windowResize = () => {
    cancelAnimationFrame(this.state.request);
    this.canvas.width = this.canvas.clientWidth;
    this.canvas.height = this.canvas.clientHeight;
    this.canvas.squareCount = Math.floor(this.canvas.width / 100 * 6);
    this.canvas.layers = [.2, .5, .8];
    this.canvas.squareContainer = [];
    this.topSpeed = .3;
    this.spreadRadius = ((this.canvas.width * this.canvas.height) / 100 / 60);
    this.timer = null;
    this.generateSquares();
    this.animate();
  }

  componentDidMount() {
    this.windowResize();
    window.addEventListener('resize', this.windowResize.bind(this));
  }

  componentDidUpdate() {
    this.clearAndDraw();
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.state.request);
    window.removeEventListener('resize', this.windowResize.bind(this), false);
  }

  clearAndDraw() {
    const ctx = this.canvas.getContext('2d');
    if (ctx) {
      ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.draw(ctx);
    }
  }

  draw(ctx) {
    this.canvas.squareContainer.forEach(square => {
      ctx.fillStyle = square.fill;
      ctx.fillRect(square.x, square.y, square.w, square.h);
    });
  }

  animate() {
    this.canvas.squareContainer.forEach(square => {
      if (square.y > this.canvas.height) square.y = -10;
      if (square.x > this.canvas.width) square.x = -10;

      if ((Math.abs(square.x - this.state.mouseX) <= this.spreadRadius) && (Math.abs(square.y - this.state.mouseY) <= this.spreadRadius)) {
        let xSym = square.x > this.state.mouseX;
        let ySym = square.y > this.state.mouseY;

        if (Math.abs(square.y - this.state.mouseY) <= this.spreadRadius)
          ySym ? square.y += this.topSpeed : square.y -= this.topSpeed;

        if (Math.abs(square.x - this.state.mouseX) <= this.spreadRadius)
          xSym ? square.x += this.topSpeed : square.x -= this.topSpeed;

      } else {
        square.x += square.velocityModifier;
        square.y += square.velocityModifier;
      }
    });

    this.setState({
      squares: this.canvas.squareContainer,
      request: requestAnimationFrame(this.animate)
    });
  }

  generateX() {
    let randX = Math.floor(Math.random() * (this.canvas.width - 10));
    for (let i = 0; i < this.canvas.squareContainer.length; i++) {
      while (this.canvas.squareContainer[i].x - 15 < randX && randX < this.canvas.squareContainer[i].x + 15)
        randX = Math.floor(Math.random() * (this.canvas.width - 10));
    }
    return randX;
  }

  generateY() {
    let randY = Math.floor(Math.random() * (this.canvas.height - 10));
    for (let i = 0; i < this.canvas.squareContainer.length; i++) {
      while (this.canvas.squareContainer[i].y - 15 < randY && randY < this.canvas.squareContainer[i].y + 15)
        randY = Math.floor(Math.random() * (this.canvas.height - 10));
    }
    return randY;
  }

  createSquare(x, y, size, layer) {
    return {
      x: x,
      y: y,
      w: size,
      h: size,
      fill: "rgba(255, 255, 255, " + layer + ")",
      velocityModifier: layer / 3
    }
  }

  generateSquares() {
    for (let i = 0; i < this.canvas.squareCount; i++) {
      let randX = this.generateX(),
          randY = this.generateY(),
          layer = this.canvas.layers[Math.floor(Math.random() * this.canvas.layers.length)];

      this.canvas.squareContainer.push(this.createSquare(randX, randY, 10, layer));
    }
  }

  resetMouse() {
    this.setState({
      mouseX: null,
      mouseY: null
    });
  }

  onMouseMove(e) {
    this.setState({
      mouseX: e.clientX,
      mouseY: e.clientY
    });

    clearTimeout(this.timer);
    this.timer = setTimeout(this.resetMouse, 10);
  }

	render() {
		return (
      <canvas ref={canvas => this.canvas = canvas} onMouseMove={this.onMouseMove.bind(this)} ></canvas>
		);
	}
}
