import React from 'react'
import { s11, s12, s1sm, vi1, vi2, vism, actus1, actus2, actussm, r31, r32, r3sm, cmbh1, cmbh2, cmbhsm, cft1, cft2, cftsm, asl1, asl2, aslsm } from '../images'
import '../css/projects.scss'

const projects = [
  {
    link: "https://www.studio1.org.uk/",
    className:  "full-width",
    image: s11,
    imageRetina: s12,
    imageMobile: s1sm,
    alt: "Studio1 Website Development",
    title: "Studio1 Website Development",
    heading: "Studio One",
    scope: "Responsive WordPress Website",
  },
  {
    link: "https://www.visualidentity.co.uk/",
    className:  "full-width",
    image: vi1,
    imageRetina: vi2,
    imageMobile: vism,
    alt: "Visual Identity Website Development",
    title: "Visual Identity Website Development",
    heading: "Visual Identity",
    scope: "Responsive WordPress Website",
  },
  {
    link: "https://actus.co.uk/",
    className:  "half-thin left",
    image: actus1,
    imageRetina: actus2,
    imageMobile: actussm,
    alt: "Actus Website Development",
    title: "Actus Website Development",
    heading: "Actus",
    scope: "Responsive WordPress Website",
  },
  {
    link: "https://www.r3roadriskreduction.co.uk/",
    className: "half-high right",
    image: r31,
    imageRetina: r32,
    imageMobile: r3sm,
    alt: "R3 Road Risk Reduction Website",
    title: "R3 Road Risk Reduction Website",
    heading: "R3 Road Risk Reduction",
    scope: "Responsive WordPress Website",
  },
  {
    link: "https://www.checkmybodyhealth.com/",
    className:  "half-high left",
    image: cmbh1,
    imageRetina: cmbh2,
    imageMobile: cmbhsm,
    alt: "Global eCommerce Solution for Health Care Products",
    title: "Global eCommerce Solution for Health Care Products",
    heading: "Check My Body Health",
    scope: "Global eCommerce Solution",
  },
  {
    link: "https://www.caribbeanfuntravel.co.uk/",
    className: "half-high right",
    image: cft1,
    imageRetina: cft2,
    imageMobile: cftsm,
    alt: "Caribbean Fun Travel Website Development",
    title: "Caribbean Fun Travel Website Development",
    heading: "Caribbean Fun Travel",
    scope: "Responsive WordPress Website",
  },
  {
    link: "https://www.aslcloud.co.uk/",
    className:  "half-thin left",
    image: asl1,
    imageRetina: asl2,
    imageMobile: aslsm,
    alt: "Altitude Safety Cloud Gas Detection System",
    title: "Altitude Safety Cloud Gas Detection System",
    heading: "ASL Cloud",
    scope: "Interactive Parallax Landing Page",
  }
]

const Project = ({ link, className, image, imageRetina, imageMobile, alt, title, heading, scope }) => (
  <a href={link} target="_blank" rel="noopener noreferrer" className={[className, "project"].join(' ')}>
    <div className="project-inner">
      <div className="project-image">
        <picture>
          <source media="(min-width: 400px)" srcSet={image + " 1x, " + imageRetina + " 2x"} />
          <img src={imageMobile} alt={alt} title={title} />
        </picture>
        <div className="overlay"></div>
      </div>
      <div className="project-content">
        <h4>{heading}</h4>
        <h5>{scope}</h5>
        <span>Visit website <i className="fa fa-long-arrow-right" aria-hidden="true"></i></span>
      </div>
    </div>
  </a>
)

const Projects = () => (
  <section className="projects fade-in delay">
    <div className="wrapper">
      <div className="heading">
        <h3>Featured Projects</h3>
      </div>
    </div>
    <div className="fw-wrapper">
      {projects.map((project) => <Project key={project.title} {...project} />)}
    </div>
  </section>
)

export default Projects
