import React from 'react'
import { Helmet } from 'react-helmet'
import { Header, Projects, Contact, Footer } from './components'

const App = () => (
  <React.Fragment>
    <Helmet>
      <title>Stephen O'Loughlin | Website Designer &amp; Developer | Responsive WordPress Websites</title>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <meta name="description" content="Professional Web Portfolio of Stephen O'Loughlin, Website Designer &amp; Developer from Northampton. Responsive WordPress Websites Specialist" />
      <meta name="author" content="Stephen O'Loughlin" />
      <meta name="keywords" content="Website Design, Website Development, Website Developer, Stephen O'loughlin, Web Portfolio, Northampton, Wordpress, Website, Designer, Repsonsive" />
      <meta property="og:type" content="Website" />
      <meta property="og:title" content="Stephen O'Loughlin | Website Designer &amp; Developer | Responsive WordPress Websites" />
      <meta property="og:site-name" content="Stephen O'Loughlin | Website Designer &amp; Developer | Responsive WordPress Websites" />
      <meta property="og:url" content="//www.sjoloughlin.com/" />
      <meta property="og:description" content="Professional Web Portfolio of Stephen O'Loughlin, Website Designer &amp; Developer from Northampton. Responsive WordPress Websites Specialist" />
      <meta property="og:image" content="https://www.sjoloughlin.com/snapshot.png" />
      <script type='application/ld+json'>{JSON.stringify({"@context":"http://schema.org","@type":"WebSite","@id":"#website","url":"https://www.sjoloughlin.com/","name":"Stephen O'Loughlin","alternateName":"Sjoloughlin","potentialAction":{"@type":"SearchAction","target":"https://www.sjoloughlin.com/?s={search_term_string}","query-input":"required name=search_term_string"}})}</script>
    </Helmet>
    <Header />
    <Projects />
    <Contact />
    <Footer />
  </React.Fragment>
)

export default App
